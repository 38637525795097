import React from 'react'

import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'

import '../styles/about.sass'

const JoinPage = () => {
    return (
        <Layout languageSwitchLink="/en/join">
            <SEO title="Participer" />
            <Banner class_suffix="deep" />
            <div className="about-container">
                <div className="about-content">
                    <p>
                        Rejoins notre communauté pour développer tes compétences en cybersécurité!
                    </p>
                    <a href="https://discord.gg/ZcYnS3GZE6">
                        <button className="signup-button">Rejoins notre Discord</button>
                    </a>
                    <h1>Ateliers techniques</h1>
                    <p>
                    Ces ateliers ont pour but d'approfondir des thèmes variés de la cybersécurité, ainsi que d'améliorer les compétences des participant(e)s. Selon les ateliers, le niveau varie de débutant à avancé.
                    </p>
                    <h1>Implique-toi!</h1>
                    S'impliquer dans un club étudiant te permet d'acquérir une bonne expérience parascolaire et c'est un plus que recherchent les recruteurs!
                    <h2>Équipe Exécutive</h2>
                    <p>
                    Composée d'étudiant(e)s motivé(e)s et passionné(e)s de Polytechnique Montréal, l'équipe exécutive est le cœur du club. Celle-ci planifie les ateliers techniques par des expert(e)s de l'industrie ou des étudiant(e)s seniors, organise des compétitions CTF (Capture-the-Flag) avec nos partenaires, promouvoit le club sur les réseaux sociaux et tisse des liens avec l'industrie au-travers de commandites et de partenariats.
                    </p>
                    <a href="https://forms.gle/Q5PedtHzsSjAjCLn6">
                        <button className="signup-button">Rejoins notre équipe Exécutive!</button>
                    </a>
                    <h2>Équipe CTF</h2>
                    <p>
                    Tout au long de l'année, l'équipe CTF, composée d'étudiant(e)s de Polytechnique, participe à de nombreuses compétitions CTF, en ligne de même qu'en présentiel. Celles-ci comprennent <a href={'https://nsec.io/'}>NorthSec</a> (Montréal), l'un des plus grands CTF au monde, le <a href={'https://hfctf.ca/'}>HackFest</a> (Québec) et le <a href={"http://www.csaw.io/"}>CSAW</a>.
                    Les membres de l'équipe ont accès à des billets fournis par le club. Retrouvez nos classement sur <a href={'https://ctftime.org/team/59057'}>CTFTime</a>!
                    </p>
                    <p>
                        Pour rejoindre notre équipe CTF, vous devez être étudiant à Polytechnique Montréal.
                    </p>
                    <a href={'https://forms.gle/QJMzWpF6hUJsw9W8A'}>
                        <button className="signup-button">Rejoins notre équipe CTF!</button>
                    </a>
                </div>
            </div>
        </Layout>
    )
}
export default JoinPage